/** @jsx jsx */
// framework imports
import * as React from "react";
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { Location } from "@reach/router";

// local imports
import Navigation from "./Navigation";
import { colors, screenSize } from "../../utils/css/themes";
import { useState, useRef, useEffect } from "react";
import Search from "./SearchHeader";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Hamburger from "../../utils/images/Nav/icon-hamburger.inline.svg";

const HeaderContainer = styled.header`
  position: relative;
  width: 100%;
  top: 0;
  z-index: 5;
  padding-top: 1rem;
  background-color: white;
  flex-shrink: 0;
`;

const AccessibleSkip = styled.a`
  position: absolute;
  background-color: white;
  padding: 1rem;
  top: 0;
  z-index: -9999;
  &:focus {
    z-index: 1;
  }
`;

const BackgroundImage = styled.div`
  position: relative;
`;

const DesktopBackground = styled.div`
  display: block;
  display: block;
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  @media (max-width: ${screenSize.smallTablet}) {
    display: none;
  }
`;
const MobileBackground = styled.div`
  display: none;
  pointer-events: none;
  @media (max-width: ${screenSize.smallTablet}) {
    display: block;
  }
`;

const Header = styled.div`
  background-color: white;
  position: relative;
  @media (max-width: ${screenSize.largeTablet}) {
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
  }
`;

const HamburgerDescription = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  color: ${colors.blue};
`;

const TitleIcon = styled.div`
  align-items: flex-start;
  display: flex;
  @media screen and (max-width: ${screenSize.largeTablet}) {
    height: 69px;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  @media (max-width: ${screenSize.largeTablet}) {
    padding-right: 0;
    display: none;
  }
`;

const MainRow = styled.div`
  display: flex;
  position: relative;
  border-bottom: 4px solid #005284;
  padding-right: 2rem;
  @media (max-width: ${screenSize.largeTablet}) {
    padding-right: 0;
    margin-top: auto;
    padding-bottom: 1rem;
  }
`;

const ImageLink = css`
  font-family: "Quicksand";
  width: 25vw;
  max-width: 380px;
  position: absolute;
  left: 12px;
  bottom: 9px;
  @media (max-width: ${screenSize.largeTablet}) {
    position: relative;
    top: 0;
    width: 206px;
  }
`;
const ToggleNavButton = styled.button`
  background-color: transparent;
  border: none;
  @media (max-width: ${screenSize.largeTablet}) {
    margin: 0 0.5rem;
  }
  &:hover {
    cursor: pointer;
  }
`;

const ButtonContainer = styled.span`
  display: none;
  @media (max-width: ${screenSize.largeTablet}) {
    align-items: center;
    margin-right: 2%;
    display: flex;
    margin-left: auto;
  }
`;

const header = (props: HeaderProps) => {
  const headerRef = useRef(null);

  const headerResize = () => {
    props.setHeaderHeight(headerRef.current.clientHeight);
  };
  // listen for screen resize
  useEffect(() => {
    props.setHeaderHeight(headerRef.current.clientHeight);
    window.addEventListener("resize", headerResize);
    return () => {
      window.removeEventListener("resize", headerResize);
    };
  }, []);

  const header_logo = useStaticQuery(graphql`
    {
      DesktopBackground: file(
        relativePath: { eq: "utils/images/Nav/header-background-green.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      MobileBackground: file(
        relativePath: {
          eq: "utils/images/Nav/header-background-green-mobil.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 770, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <HeaderContainer ref={headerRef}>
      <Location>
        {({ location }) => (
          <Header>
            <AccessibleSkip href="#main">Skip to main content</AccessibleSkip>

            <TopRow>
              <Search />
            </TopRow>
            <MainRow>
              <TitleIcon>
                <Link css={ImageLink} to="/">
                  <StaticImage
                    alt={"site logo"}
                    src="../../utils/images/Nav/skd-dfyf-logo.png"
                  />
                </Link>
              </TitleIcon>
              <ButtonContainer>
                <ToggleNavButton
                  onClick={() => props.setShowNav(!props.showNav)}
                >
                  <Hamburger />
                  <HamburgerDescription>menu</HamburgerDescription>
                </ToggleNavButton>
              </ButtonContainer>
              <Navigation
                slug={location.pathname}
                setShowNav={() => props.setShowNav()}
                showNav={props.showNav}
                headerHeight={props.headerHeight}
              />
            </MainRow>
            {location.pathname !== "/" && (
              <BackgroundImage>
                <DesktopBackground>
                  <GatsbyImage
                    image={
                      header_logo.DesktopBackground.childImageSharp
                        .gatsbyImageData
                    }
                    alt={"site background"}
                  />
                </DesktopBackground>
                <MobileBackground>
                  <GatsbyImage
                    image={
                      header_logo.MobileBackground.childImageSharp
                        .gatsbyImageData
                    }
                    alt={"site background"}
                  />
                </MobileBackground>
              </BackgroundImage>
            )}
          </Header>
        )}
      </Location>
    </HeaderContainer>
  );
};

interface HeaderProps {
  headerHeight: -1;
  setHeaderHeight: any;
  setShowNav: any;
  showNav: boolean;
}

export default header;
